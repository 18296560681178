@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
    margin: 0px;
    background: #f2f2fd;
    overflow-y: scroll;
}



@media (min-width: 681px) {
    .cards-area{
        margin-top: 30px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        /* gap: 20px;  */
        /* grid-template-columns: repeat(1, minmax(0, 1fr)) !important; */
    }
}

@media (min-width: 371px) {
    .cards-area{
        margin-top: 30px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        /* gap: 20px;  */
        /* grid-template-columns: repeat(1, minmax(0, 1fr)) !important; */
    }
}

  ::-webkit-scrollbar {
     width: 2px;
     height: 3px;
     cursor:pointer !important;
   }
       
   ::-webkit-scrollbar-track {
     box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
   }
       
   ::-webkit-scrollbar-thumb {
     background: #cf5c36;
     background-color: darkgrey;
     outline: 1px solid slategrey;
     border-radius: 2px;
   }

/*My blog Table design */

/* tr {
  border: 0;
  display: block;
  margin-top: 16px;

} */
/* .solid {
  border: 1px #9B9B9B solid;
  border-radius: 8px;
} */

/* td {
  padding: 5px;
} */


.controls {
  position: absolute;
  bottom: 20px;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  height: 40px;
  display: flex;
  align-items: center;
}

.slider {
  padding: 22px 0px;
}

.zoom-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 2px;
  border-top: 5px solid #fff;
  border-bottom: 5px solid #fff;
  background: #3f51b5;
  width: 100%;
}

.zoom-range::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.zoom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.controls:hover input[type='range']::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  border-radius: 50%;
}

.controls:hover input[type='range']::-moz-range-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}/*Filter styles*/
/* .saturate { filter: saturate(3); }
.grayscale { filter: grayscale(100%); }
.contrast { filter: contrast(160%); }
.brightness { filter: brightness(0.25); }
.blur { filter: blur(3px); }
.invert { filter: invert(100%); }
.sepia { filter: sepia(100%); }
.huerotate { filter: hue-rotate(180deg); }
.rss.opacity { filter: opacity(50%); } */


::-webkit-scrollbar {
  display: none;
}

.rdo-style-cs {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: top;
}

.rdo-style-cs label span span.css-hyxlzm {
  position: relative;
  min-width: 24px;
  min-height: 24px;
  align-items: center;
  justify-content: center;
}

.rdo-style-cs label span span.css-hyxlzm:before {
  content: "";
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  background: #fff;
  border: 3px solid #666;
  left: 2px;
  top: 2px;
  transition: all .5s ease-in-out;
  padding: 0;
  margin: 0;
  right: 0;
  text-align: left;
}

.rdo-style-cs label span span.css-hyxlzm:after {
  content: "";
  width: 12px;
  height: 12px;
  display: block;
  position: absolute;
  background: #708BE2;
  transition: all .5s ease-in-out;
  opacity: 0;
  visibility: hidden;
  /* right: 0; */
  /* bottom: 0; */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.rdo-style-cs label span input[type="radio"]:checked+span.css-hyxlzm:after {
  opacity: 1;
  visibility: visible;
}



.rdo-style-cs label span span.css-hyxlzm svg{display:none !important;}

/* .MuiBox-root.css-130f8nx a:hover {
  background: #7580e1;
  border-radius: 20px;
  color: #fbeff200;
} */

.con1 {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.con1 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: white;
  border : 2px solid #666666;
}

.checked {
  position: absolute;
  border-radius: 10%;
}

/* When the radio button is checked, add a blue background */
.con1 input:checked ~ .checked {
  background: #708BE2;
  margin-left: 0;
  margin-top: 0;
  height: 13px;
  width: 13px;
  left: 3px;
  top: 4px;
}
label.con1 {
  left: 11px;
  /* width: 20px; */
  /* height: 20px; */
}

* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  margin: 0;
  padding: 0;
}
/* 
.h-cs-full.h-full {
  height: 92%;
} */

/* auto complete border  */
.MuiFormControl-root.MuiFormControl-marginNormal.MuiFormControl-fullWidth.MuiTextField-root.sc-gswNZR.bFLssH{
border: 0 !important;
}

.header_bar{
  background-color: "#6A87E0";
}
.selected-menu-item {
  background-color: #6A87E0; /* Background color for the selected item */
  color: #fff; /* Text color for the selected item */
  padding: 5px 10px; /* Adjust the padding to your preference */
}

.benifit-img {
  width: 100%;
  text-align: center;
  margin: 20px;
  font-size: 40px;
  margin-top: 75px;
}
.slick-slides.MuiBox-root.css-yxpm00 div img {
  margin: 0px auto;
}
.css-yxpm00 h4.MuiTypography-root.MuiTypography-h4.css-l9hyri-MuiTypography-root {
  margin-bottom: 50px;
}
.css-yxpm00 {
  padding: 50px 30px 50px !important;
}
.slick-prev:before{
  font-family: 'slick';
  font-size: 30px;
  line-height: 1;
  opacity: .75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-slider {
  position: relative;
  display: block;
  padding: 55px;
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-slider.slick-initialized button {
  top: 86px;
}

img.MuiImageListItem-img.sssss {
  width: 78%;
}
li.MuiImageListItem-root.MuiImageListItem-standard.aaaaaa.css-ta95xa-MuiImageListItem-root {
  align-items: center;
  text-align: center;
}


body, p, ul li, p, h1, h2, h3, h4, h5, h6, span, a, button {
  font-family: 'Poppins', sans-serif !important;
}

.banner-section_home {
  position: relative;
  padding-top: 4rem;
  padding-bottom: 2rem;
}

.banner-section_home-inner {
  width: 100%;
  max-width: 1230px;
  padding: 0 15px;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;
}

.banner-section_home:after {
  content: "";
  position: absolute;
  left: -50px;
  bottom: 0;
  width: auto;
  height: 320px;
  background: #fff;
  border-radius: 100% 100% 0 0;
  right: -50px;
  /* z-index: -1; */
}

.banner-section_home-inner h6.MuiTypography-root {
  font-size: 24px;
  width: 100%;
  max-width: 880px;
  margin-left: auto;
  margin-right: auto;
}

.btn_free_trial.MuiBox-root button.MuiButtonBase-root {
  background: #fff;
  font-size: 22px;
  padding-left: 25px !important;
  padding-right: 25px !important;
  text-transform: inherit;
  letter-spacing: 0;
  border-radius: 6px !important;
  color: #4C6EDA !important;
}

.banner-section_home-inner img {
  width: auto !important;
}

.wider-container {
  margin: 0 auto;
  width: 100%;
  max-width: 1750px;
  padding: 0 15px;
}

.section_space {
  margin-bottom: 80px;
}

.flex.w-screen.overflow-hidden.bg-mediumLightGray {
  background: #fff;
}

.tolls_section * {
  text-align: center;
}

.section_sub_heading {
  font-size: 20px !important;
  width: 100%;
  max-width: 1300px;
  margin-left: auto !important;
  margin-right: auto !important;
  letter-spacing: 0 !important;
}


.more_tools.MuiBox-root .MuiPaper-root.MuiCard-root.card-cont {
  width: calc(25% - 30px);
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 30px;
  transition: all .5s ease-in-out;
  border-radius: 8px;
  padding: 6px;
  border: 1px solid #DCDCDC;
}

.more_tools.MuiBox-root .MuiTypography-root.MuiTypography-h6 {
  font-size: 25px;
  color: #000000;
  margin: 10px 0;
  font-family: 'Poppins', sans-serif !important;
  letter-spacing: 1px;
}

.more_tools.MuiBox-root .MuiPaper-root.MuiCard-root.card-cont  p.MuiTypography-root {
  font-size: 16px;
  margin: 0;
  color: #5a5a5a;
  line-height: 1.7;
}

.more_tools.MuiBox-root .MuiPaper-root.MuiCard-root.card-cont:hover {
  background: #4C6EDA;
  border-color: #4C6EDA;
}

.more_tools.MuiBox-root .MuiPaper-root.MuiCard-root.card-cont:hover .MuiTypography-root.MuiTypography-h6 {
  color: #fff;
}

.more_tools.MuiBox-root .MuiPaper-root.MuiCard-root.card-cont:hover p.MuiTypography-root{
  color: #fff;
}

.more_tools.MuiBox-root .MuiPaper-root.MuiCard-root.card-cont:hover img {
  filter: brightness(0) invert(1);
}

.more-tools-btn.MuiBox-root button.MuiButtonBase-root {
  background: #4C6EDA;
  box-shadow: none;
  text-transform: inherit;
  letter-spacing: 0;
  font-size: 18px;
  min-width: 220px;
  padding: 14px;
  border-radius: 10px;
  margin-top: 10px;
}

.more-tools-btn.MuiBox-root button.MuiButtonBase-root:hover {
  background: #000;
}

.benfit-section.MuiBox-root {
  padding: 50px 0;
  position: relative;
}

.btn_white_bg button.MuiButtonBase-root {
  background: #fff;
  box-shadow: none;
  text-transform: inherit;
  letter-spacing: 0;
  font-size: 18px;
  min-width: 156px;
  padding: 14px;
  border-radius: 10px;
  margin-top: 10px;
  color: #4C6EDA;
}

.btn_white_bg button.MuiButtonBase-root:hover {
  background: #000;
  color:#fff;
}

.partner-section h4.MuiTypography-root {
  font-size: 45px;
  margin: 20px 0;
}

.partner-section .slick-slide .MuiBox-root {
  background: #fff;
  min-height: 160px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 6px;
}

.partner-section .slick-slide>div {
  margin: 0 15px;
}

.partner-section  .slick-slider.slick-initialized {
  padding-left: 10px;
  padding-right: 10px;
}

.partner-section .slick-slider.slick-initialized button.slick-arrow{
  top: 50%;
  background: #E4E4E4;
  width: 45px;
  height: 45px;
  color: #000;
  border-radius: 30px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.partner-section .slick-slider.slick-initialized button.slick-arrow.slick-prev {
  left: 0;
  
}

.partner-section .slick-slider.slick-initialized button.slick-arrow.slick-next{
  right: 0;
}

.partner-section .slick-slider.slick-initialized button.slick-arrow:before {
  color: #000;
  opacity: 1;
  font-size: 24px;
  line-height: 45px;
  display: block;
}


.sec_main-heading {
  text-align: center;
  font-size: 45px !important;
  letter-spacing: 1px !important;
  font-weight: 700 !important;
  color: #00152A !important;
  margin-bottom: 30px !important;
  font-family: 'Poppins', sans-serif !important;
}

.team_memebers ul.MuiImageList-root li {
  width: 20%;
  padding: 0 15px;
  margin: 20px 0;
}

.team_memebers ul.MuiImageList-root {
  gap: 0 !important;
  margin-left: -15px;
  margin-right: -15px;
}

.team_memebers ul.MuiImageList-root li .MuiImageListItemBar-title {
  font-size: 22px;
  color: #00152A;
  font-family: 'Poppins', sans-serif !important;
  line-height: normal;
  margin-bottom: 4px;
  width: 100%;
}

.team_memebers ul.MuiImageList-root li .MuiImageListItemBar-subtitle {
  font-size: 18px;
  color: #000000;
  font-family: 'Poppins', sans-serif !important;
  line-height: normal;
  font-weight: 300;
  text-overflow: inherit;
  white-space: normal;
  overflow: visible;
}

.review_cls .MuiPaper-root {
  height: auto;
  float: left;
  width: calc(25% - 30px);
  margin: 15px;
  background: none;
  box-shadow: 0 8px 20px rgba(0,0,0,0.20);
  border: none !important;
  background: #fff;
  padding:10px;
}

.review_sections {
  padding: 20px 0 60px 0;
  background: #6A87E0 !important;
  position: relative;
}

.review_sections:after {
  content: "";
  background: #fff;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 200px;
}

.review_sections:before {
  content: "";
  position: absolute;
  left: -50px;
  bottom: 150px;
  width: auto;
  height: 320px;
  background: #fff;
  border-radius: 0 0 100% 100%;
  right: -50px;
  /* z-index: -1; */
}

.review_sections .wider-container {
  position: relative;
  z-index: 1;
}

.review_sections .sec_main-heading {
  margin-bottom: 10px !important;
}
.review_cls {
  margin-bottom: 0;
}

.review_cls .MuiPaper-root .MuiTypography-root.MuiTypography-h6 {
  color: #283854;
  font-size: 22px;
  line-height: 1.4;
  margin-bottom: 10px;
  font-family: 'Poppins', sans-serif !important;
}

.review_cls .MuiPaper-root p.MuiTypography-root {
  color: #000;
  margin-bottom: 21px;
}

.review_footer_btm ul {
  display: block;
  margin-right: 15px;
}

.review_footer_btm p.MuiTypography-root {
  margin-bottom: 0 !important;
  color: #283854 !important;
  font-weight: 600;
  font-size: 16px;
}

.review_footer_btm svg {
  width: 22px;
  height: 22px;
  fill: #FDD508;
}

.review_sections {
  padding: 20px 0 0px 0;
}

.footer-section.MuiBox-root {
  padding-left: 0;
  padding-right: 0;
}


.footer_cls_first h5.MuiTypography-root {
  margin-bottom: 20px;
  font-size: 35px;
}

.footer_cls_first  h6.MuiTypography-root {
  font-size: 17px;
  line-height: 1.7;
}

.footer_multi_cls h5.MuiTypography-root {
  font-size: 26px;
  margin-bottom: 20px;
}

.footer_multi_cls p.MuiTypography-root {
  font-size: 17px;
  line-height: 1.4;
  margin: 10px 0;
}

.footer_multi_cls .MuiGrid-root.MuiGrid-container {
  margin-left: 0;
  padding-left: 100px;
}

.footer_multi_cls .MuiGrid-root.MuiGrid-container .MuiGrid-item.MuiGrid-grid-md-4 {
  padding-left: 16px;
  margin-bottom: 30px !important;
}

.footer-section .MuiGrid-root.MuiGrid-container {
  width: 100%;
  margin-left: 0;
}

.footer_cls_first {
  margin-bottom: 30px !important;
}

.footer-btm {
  font-size: 16px;
  font-weight: normal;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
}

p.MuiTypography-root.MuiTypography-body1.footer-hover.css-otmmpd-MuiTypography-root:hover {
  cursor: pointer;
  border-bottom: 2px solid #5b62c8;
  width: 200px;
}

.fixed.top-0.left-0.right-0 button.h-10.w-full.text-sm.tracking-wider {
  width: 100%;
  max-width: 150px;
  text-align: center;
  right: 0;
  left: 0;
  float: left;
  margin: 18px auto;
}
@media only screen and (max-width:1650px){

  .wider-container {
    max-width: 1500px;
  }

  .footer_cls_first h5.MuiTypography-root {
    font-size: 30px;
}
}


@media only screen and (max-width:1440px){

  .wider-container {
    max-width: 1300px;
  }
}

.disable-selecting{
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

/* select-none */